import { getFirebaseAuthInfra } from "@/infrastructure/firebase_auth/client"
import { FirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/core/firestore"
import { getFirebaseApp } from "@/infrastructure/firebase/client"
import { getFirestore } from "firebase/firestore"

export function getFirebaseFirestoreInfra(): FirebaseFirestoreInfra {
  const firebaseApp = getFirebaseApp()

  // 認証状態は初期化する必要があるので、実行しておく
  getFirebaseAuthInfra()

  return new FirebaseFirestoreInfra(getFirestore(firebaseApp))
}
