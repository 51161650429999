import FirebaseAuthInfra from "@/infrastructure/firebase_auth/core/auth"
import { getFirebaseApp } from "@/infrastructure/firebase/client"
import { getAuth, type Auth, type User } from "firebase/auth"

export function getFirebaseAuthInfra(): Auth {
  const firebaseApp = getFirebaseApp()

  return new FirebaseAuthInfra(getAuth(firebaseApp)).auth
}

export function getCurrentUserId(): string | undefined {
  return getFirebaseAuthInfra().currentUser?.uid
}

export function getCurrentUser(): User | null {
  return getFirebaseAuthInfra().currentUser
}
