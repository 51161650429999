// トーストの表示時間
export const kToastDuration = 3000
export const kEmailPassword = "[０-９Ａ-Ｚａ-ｚぁ-んァ-ヶ亜-熙纊-黑]+"
export const kRefetchIntervalTime = 30 * 60 * 1000

/**
 * If you change this variable, please also change the timezone of ```test``` command in package.json
 */
export const kTimeZone = "Asia/Tokyo"

export const OVERTIME_STATUS_PAGE_SIZE = 20
