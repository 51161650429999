import { clsx, type ClassValue } from "clsx"
import { endOfDay, startOfDay } from "date-fns"
import {
  utcToZonedTime as originalUtcToZonedTime,
  zonedTimeToUtc as originalZonedTimeToUtc,
} from "date-fns-tz"
import Hashids from "hashids"
import { twMerge } from "tailwind-merge"
import { v4 as uuidv4 } from "uuid"

import { kTimeZone } from "../constants/constants"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const calculateWorkingHoursFromHHMM = (
  startTime?: string | null,
  endTime?: string | null,
  breakTimeInMinutes?: number | null
): string | null => {
  if (!startTime || !endTime) {
    return null
  }

  // 休憩時間がnullまたはundefinedの場合、0として扱う
  const breakTime = breakTimeInMinutes ?? 0

  const [startHour, startMinute] = startTime.split(":").map(Number)
  const [endHour, endMinute] = endTime.split(":").map(Number)

  const totalStartMinutes = (startHour || 0) * 60 + (startMinute || 0)
  const totalEndMinutes = (endHour || 0) * 60 + (endMinute || 0)

  let workingMinutes
  if (totalStartMinutes > totalEndMinutes) {
    workingMinutes = 24 * 60 - totalStartMinutes + totalEndMinutes - breakTime
  } else {
    workingMinutes = totalEndMinutes - totalStartMinutes - breakTime
  }

  const workingHours = Math.floor(workingMinutes / 60)
  workingMinutes %= 60

  if (workingHours >= 24 || workingMinutes < 0) {
    return null
  }

  const hoursText = workingHours > 0 ? `${workingHours}時間` : ""
  const minutesText = workingMinutes >= 0 ? `${workingMinutes}分` : ""

  if (!hoursText && minutesText === "0分") {
    return null
  }

  if (!hoursText && !minutesText) {
    return null
  }

  if (hoursText && minutesText === "0分") {
    return hoursText
  }

  return `${hoursText}${minutesText}`
}

export const calculateWorkingHoursFromddHHMM = (
  startTime?: string | null,
  endTime?: string | null,
  breakTimeInMinutes?: number | null
): string | null => {
  if (
    !startTime ||
    !endTime ||
    breakTimeInMinutes === null ||
    breakTimeInMinutes === undefined
  ) {
    return null
  }

  const [startDay, startHour, startMinute] = startTime.split(":").map(Number)
  const [endDay, endHour, endMinute] = endTime.split(":").map(Number)

  const totalStartMinutes =
    (startDay || 0) * 24 * 60 + (startHour || 0) * 60 + (startMinute || 0)
  const totalEndMinutes =
    (endDay || 0) * 24 * 60 + (endHour || 0) * 60 + (endMinute || 0)

  let workingMinutes = totalEndMinutes - totalStartMinutes - breakTimeInMinutes
  const sign = workingMinutes >= 0 ? 1 : -1

  const workingHours = Math.floor(Math.abs(workingMinutes) / 60) * sign
  workingMinutes = (Math.abs(workingMinutes) % 60) * sign

  const hoursText = workingHours !== 0 ? `${workingHours}時間` : ""
  let minutesText = `${workingMinutes}分`

  if (!!workingHours && workingMinutes === 0) {
    minutesText = ""
  }

  return `${hoursText}${minutesText}`
}

export const calculateOvertimeFromHHMM = (
  baseWorkingTime: string | null,
  workingTime: string | null
): string | null => {
  if (!baseWorkingTime || !workingTime) {
    return null
  }

  const baseTimeInMinutes = convertTimeToMinutes(baseWorkingTime)
  const targetTimeInMinutes = convertTimeToMinutes(workingTime)

  const overtimeInMinutes = targetTimeInMinutes - baseTimeInMinutes

  if (overtimeInMinutes < 0) {
    return null
  }

  const overtimeHours = Math.floor(Math.abs(overtimeInMinutes) / 60)
  const overtimeMinutes = Math.abs(overtimeInMinutes) % 60

  const hoursText = overtimeHours !== 0 ? `${overtimeHours}時間` : ""
  let minutesText = `${overtimeMinutes}分`

  if (!!overtimeHours && overtimeMinutes === 0) {
    minutesText = ""
  }

  return `${hoursText}${minutesText}`
}

export const convertTimeToMinutes = (time: string): number => {
  const [hours, minutes] = time
    .split("時間")
    .map((part) => part.replace("分", ""))

  return (Number(hours) || 0) * 60 + (Number(minutes) || 0)
}

// 分を時間と分に変換する
export const convertMinutesToHoursAndMinutesText = (
  minutes: number
): string => {
  const hours = Math.floor(minutes / 60)
  const remainMinutes = minutes % 60

  const hoursText = hours > 0 ? `${hours}時間` : ""
  const minutesText = remainMinutes > 0 ? `${remainMinutes.toFixed(0)}分` : ""

  // ３時間0分のときは「３時間」と表示する
  if (hoursText && minutesText === "0分") {
    return hoursText
  }

  return `${hoursText}${minutesText}`
}

// 時間がマイナス値かどうかを判定する
export const isMinusTime = (time?: string | null): boolean => {
  if (!time) {
    return false
  }

  return time.startsWith("-")
}

export function createUuid(length = 8) {
  const hashIds = new Hashids(uuidv4(), length)
  const id = hashIds.encode([1, 2, 3])

  return id
}

export const formatDateFromddHHMM = (
  baseDate: Date,
  timeString?: string | null
) => {
  if (!timeString) return new Date()

  const [days, hours, minutes] = timeString.split(":").map(Number)

  return new Date(
    baseDate.getFullYear(),
    baseDate.getMonth(),
    days,
    hours,
    minutes
  )
}

export const getStartAndEndOfDayUTC = (date: Date) => {
  const startOfDayJST = startOfDay(date)
  const endOfDayJST = endOfDay(date)
  const startOfDayUTC = zonedTimeToUtc(startOfDayJST)
  const endOfDayUTC = zonedTimeToUtc(endOfDayJST)

  return { startOfDayUTC, endOfDayUTC }
}

export const formatMinutesToTime = (minutes?: number) => {
  if (minutes === null || minutes === undefined) return null

  if (minutes === 0) return "0:00"

  const isNegative = minutes < 0
  const absoluteMinutes = Math.abs(minutes)

  return `${isNegative ? "-" : ""}${Math.floor(absoluteMinutes / 60)}:${
    absoluteMinutes % 60 < 10 ? "0" : ""
  }${absoluteMinutes % 60}`
}

export const calculateTimeParts = (totalMinutes: number | undefined) => ({
  hours: totalMinutes ? Math.floor(totalMinutes / 60) : 0,
  minutes: totalMinutes ? totalMinutes % 60 : 0,
})

// ２つの"HH:mm"から差分を出して"HH:mm"で出すヘルパー
export const calculateTimeDifference = (
  time1: string | null,
  time2: string | null
) => {
  if (!time1 || !time2) return null

  const start = new Date(`2000-01-01T${time1}:00Z`)
  const end = new Date(`2000-01-01T${time2}:00Z`)
  const diff = Math.abs(end.getTime() - start.getTime())
  const minutes = Math.floor(diff / 1000 / 60)
  const hours = Math.floor(minutes / 60)
  const remainMinutes = minutes % 60

  // 00:00のときはnullを返す
  if (hours === 0 && remainMinutes === 0) return null

  return `${hours}:${remainMinutes < 10 ? "0" : ""}${remainMinutes}`
}

export const convertMinutesToHoursAndMinutes = (
  minutes: number
): { hours: number; minutes: number } => {
  const hours = Math.floor(minutes / 60)
  const remainMinutes = minutes % 60

  return { hours, minutes: remainMinutes }
}

interface Period {
  start: Date
  end: Date
}

export const getLastSixtyMonthsPeriods = (
  closingDay: number,
  today: Date = new Date()
) => {
  if (closingDay === 0) {
    return getLastSixtyMonthsPeriodsOnEndOfMonth(closingDay, today)
  }
  let latestPeriodEnd = new Date(
    today.getFullYear(),
    today.getMonth(),
    closingDay
  )

  const isPastClosingDay = today.getDate() > closingDay
  if (isPastClosingDay) {
    latestPeriodEnd.setMonth(latestPeriodEnd.getMonth() + 1)
  }

  const periods: Period[] = []
  for (let i = 0; i < 60; i++) {
    const periodEnd = new Date(
      latestPeriodEnd.getFullYear(),
      latestPeriodEnd.getMonth() - i,
      closingDay
    )
    const periodStart = new Date(
      periodEnd.getFullYear(),
      periodEnd.getMonth() - 1,
      closingDay + 1
    )

    periods.push({ start: periodStart, end: periodEnd })
  }

  return periods
}

const getLastSixtyMonthsPeriodsOnEndOfMonth = (
  closingDay: number,
  today: Date = new Date()
) => {
  // closingDay（月末）> todayなので+1ヶ月
  // closingDayが0の場合、Dateの日付として入力すると前月の最終日になるので+1ヶ月
  // 合計+2ヶ月
  let latestPeriodEnd = new Date(
    today.getFullYear(),
    today.getMonth() + 2,
    closingDay
  )

  const periods: Period[] = []
  for (let i = 0; i < 60; i++) {
    const periodEnd = new Date(
      latestPeriodEnd.getFullYear(),
      latestPeriodEnd.getMonth() - i,
      closingDay
    )
    const periodStart = new Date(
      periodEnd.getFullYear(),
      periodEnd.getMonth(),
      closingDay + 1
    )

    periods.push({ start: periodStart, end: periodEnd })
  }

  return periods
}

// テキストが日本語かどうかを判定する
export const isJapanese = (text: string) => {
  return text.match(/[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\u31f0-\u31ff]/)
}

export const utcToZonedTime = (date: Date | string | number) => {
  return originalUtcToZonedTime(date, kTimeZone)
}

export const zonedTimeToUtc = (date: Date | string | number) => {
  return originalZonedTimeToUtc(date, kTimeZone)
}

// Removes the @folder part from the path
export const removeParallelRouteSegment = (path: string): string => {
  return path.replace(/\/@[^/]+/g, "")
}
